import { PageHeader } from "@ant-design/pro-layout";
import { Tenant } from "@chirpstack/chirpstack-api-grpc-web/api/tenant_pb";
import { Breadcrumb, Card, Col, Row, Select, Space, Statistic } from "antd";
import { Link } from "react-router-dom";
import { Bar, Line } from "react-chartjs-2";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

import HubStore, { Alarm } from "../../stores/HubStore";
import { useEffect, useState } from "react";
import DataTable, { GetPageCallbackFunc } from "../../components/DataTable";
import Table, { ColumnsType } from "antd/es/table";
import moment from "moment";

interface IProps {
  tenant: Tenant;
}

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Water Flow Over Time",
    },
  },
};

const alarmOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Alarm Over Time",
    },
  },
};

const alarms = [
  "Alarm on burst",
  "Alarm on low battery",
  "Alarm on overflow",
  "Alarm on reverse flow",
  "Alarm on water leakage",
  "Alarm on wrong installation",
  "Diameter",
  "Medium",
];

interface ChartData {
  labels: Array<string>;
  datasets: Array<any>;
}

const ListData = (props: IProps) => {
  const deviceOptions = [
    { value: "cc0de70000027952", label: "Water Meter - Ati Kos" },
    { value: "cc0de70000027960", label: "Water Meter - Prishtine" },
  ];

  const [selectedDeviceIDs, setSelectedDeviceIDs] = useState(deviceOptions.map(device => device.value));
  const [selectedTimeRange, setSelectedTimeRange] = useState("7d");
  const [selectedAlarm, setSelectedAlarm] = useState("alarm_burst");
  const [tableData, setTableData] = useState<Array<Alarm>>([]);
  const [totalFlow, setTotalFlow] = useState(0);
  const [reverseFlow, setReverseFlow] = useState(0);
  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        label: "Flow",
        data: [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  });
  const [alarmData, setAlarmData] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        label: "Alarm",
        data: [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  });

  const columns: ColumnsType<Alarm> = [
    {
      title: "Recorded At",
      dataIndex: "lastSeenAt",
      key: "lastSeenAt",
      width: 250,
      render: (text, record) => {
        return moment(record.recordedAt).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "DevEUI",
      dataIndex: "devEui",
      key: "devEui",
      width: 250,
      render: (text, record) => <Link to={`/tenants/devices/${record.deviceId}`}>{record.deviceId}</Link>,
    },
    {
      title: "Alarm name",
      dataIndex: "alarmName",
      key: "alarmName",
      render: (text, record) => {
        return record.name;
      },
    },
  ];

  const getPage = (limit: number, offset: number, callbackFunc: GetPageCallbackFunc) => {
    const data = tableData.slice(offset, offset + limit);
    callbackFunc(tableData.length, data);
  };

  useEffect(() => {
    const getStartDate = () => {
      const today = new Date();
      switch (selectedTimeRange) {
        case "7d":
          return new Date(today.setDate(today.getDate() - 7));
        case "1m":
          return new Date(today.setMonth(today.getMonth() - 1));
        case "3m":
          return new Date(today.setMonth(today.getMonth() - 3));
        default:
          return new Date(today.setDate(today.getDate() - 7));
      }
    };

    const request = {
      deviceIDs: selectedDeviceIDs,
      startDate: getStartDate(),
    };

    HubStore.dailyFlow(request, res => {
      res = res.reverse();
      const labels = res.map(item => new Date(item.time).toDateString());
      const data = res.map((item: any) => item.value);

      setChartData({
        labels,
        datasets: [
          {
            label: "Flow",
            data,
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      });
    });

    HubStore.totalFlow(request, res => {
      const total = res.reduce((sum, item) => sum + item.totalFlow, 0);
      setTotalFlow(total);
    });

    const reverseFlowRequest = {
      deviceIDs: selectedDeviceIDs,
      startDate: getStartDate(),
      direction: "reverse_flow_count_liters",
    };
    HubStore.totalFlow(reverseFlowRequest, res => {
      const total = res.reduce((sum, item) => sum + item.totalFlow, 0);
      setReverseFlow(total);
    });

    const alarmOverTimeRequest = {
      deviceIDs: selectedDeviceIDs,
      startDate: getStartDate(),
      alarmName: selectedAlarm 
    };
    HubStore.alarmsOverTime(alarmOverTimeRequest, res => {
      const labels = res.map(item => new Date(item.time).toDateString());
      const data = res.map((item: any) => item.value);
      console.log(labels)

      setAlarmData({
        labels,
        datasets: [
          {
            label: alarmOverTimeRequest.alarmName,
            data,
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      });
    });

    HubStore.lastAlarms(request, (resp: Array<Alarm>) => {
      setTableData(resp);
    });
  }, [selectedDeviceIDs, selectedTimeRange, selectedAlarm]);

  const handleDeviceChange = (value: string[]) => {
    setSelectedDeviceIDs(value);
  };

  const handleTimeRangeChange = (value: string) => {
    setSelectedTimeRange(value);
  };

  const handleAlarmChange = (value: string) => {
    setSelectedAlarm(value);
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }} size="large">
      <PageHeader
        breadcrumbRender={() => (
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>Tenants</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                <Link to={`/tenants/${props.tenant.getId()}`}>{props.tenant.getName()}</Link>
              </span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>Data</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        )}
        title="Data"
      />
      <Card>
        <Space direction="vertical" style={{ width: "100%" }} size="large">
          <Select
            mode="multiple"
            defaultValue={selectedDeviceIDs}
            style={{ width: 400 }}
            onChange={handleDeviceChange}
            options={deviceOptions}
          />

          <Select defaultValue={selectedTimeRange} style={{ width: 200 }} onChange={handleTimeRangeChange}>
            <Select.Option value="7d">Last 7 Days</Select.Option>
            <Select.Option value="1m">Last Month</Select.Option>
            <Select.Option value="3m">Last 3 Months</Select.Option>
          </Select>
          <Row gutter={16}>
            <Col span={12}>
              <Card bordered={false}>
                <Statistic
                  title="Total Flow"
                  value={totalFlow}
                  precision={2}
                  valueStyle={{ color: "#3f8600" }}
                  prefix={<ArrowUpOutlined />}
                  suffix="L"
                />
              </Card>
            </Col>
            <Col span={12}>
              <Card bordered={false}>
                <Statistic
                  title="Reverse Flow"
                  value={reverseFlow}
                  precision={2}
                  valueStyle={{ color: "#cf1322" }}
                  prefix={<ArrowDownOutlined />}
                  suffix="L"
                />
              </Card>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <Card title="Flow over period">
                <Bar options={options} data={chartData} />
              </Card>
            </Col>
            <Col span={12}>
              <Card title="Flow over period">
                <Line options={options} data={chartData} />
              </Card>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Card title="Past alarms">
                <Table columns={columns} dataSource={tableData} pagination={false}/>
              </Card>
            </Col>
            <Col span={12}>
              <Space direction="vertical" style={{ width: "100%" }} size="large">
                <Select
                  defaultValue={selectedAlarm}
                  style={{ width: 200}}
                  onChange={handleAlarmChange}>
                  <Select.Option value="alarm_burst">Alarm Burst</Select.Option>
                  <Select.Option value="alarm_overflow">Alarm Overflow</Select.Option>
                  <Select.Option value="alarm_low_battery">Alarm Low Battery</Select.Option>
                  <Select.Option value="alarm_reverse_flow">Alarm Reverse Flow </Select.Option>
                  <Select.Option value="alarm_water_leakage">Alarm Water Leakage</Select.Option>
                  <Select.Option value="alarm_wrong_installation">Alarm Wrong Installation</Select.Option>
                </Select>
                <Card title="Alarms Over Time">
                  <Line options={alarmOptions} data={alarmData} />
                </Card>
              </Space>
            </Col>
          </Row>
        </Space>
      </Card>
    </Space>
  );
};

export default ListData;
