import { EventEmitter } from "events";

import { HandleError } from "./helpers";

export interface HubRequest {
  startDate?: Date;
  endDate?: Date;
  deviceIDs: Array<string>;
  direction?: string;
  alarmName?: string
}

export interface TotalFlowResponse {
  deviceId: string;
  totalFlow: number;
}

export interface TimeEntry {
  time: Date;
  value: number;
}

export interface Alarm {
  deviceId: string;
  name: string;
  recordedAt: Date;
}

class HubStore extends EventEmitter {
  totalFlow = (request: HubRequest, callbackFunc: (res: Array<TotalFlowResponse>) => void) => {
    const url: string = createHubRequestUrl("https://hub.iotns.fourbyte.net/series/total-flow", request);
    fetch(url)
      .then(res => res.json())
      .then(data => {
        const totalFlowData: Array<TotalFlowResponse> = data.map((item: any) => ({
          deviceId: item.device_id,
          totalFlow: item.total_flow,
        }));
        callbackFunc(totalFlowData);
      })
      .catch(err => HandleError(err));
  };

  dailyFlow = (request: HubRequest, callbackFunc: (res: Array<TimeEntry>) => void) => {
    const url = createHubRequestUrl("https://hub.iotns.fourbyte.net/series/flow-by-day", request);
    fetch(url)
      .then(res => res.json())
      .then(data => callbackFunc(data))
      .catch(err => HandleError(err));
  };

  lastAlarms = (request: HubRequest, callbackFunc: (res: Array<Alarm>) => void) => {
    const url = createHubRequestUrl("https://hub.iotns.fourbyte.net/series/last-alarms", request);
    fetch(url)
      .then(res => res.json())
      .then(data => {
        return data.map((item: any) => ({
          deviceId: item.device_id,
          recordedAt: item.recorded_at,
          name: item.alarm_name
        }))
      })
      .then(data => callbackFunc(data))
      .catch(err => HandleError(err));
  };

  alarmsOverTime = (request: HubRequest, callbackFunc: (res: Array<TimeEntry>) => void) => {
    const url = createHubRequestUrl("https://hub.iotns.fourbyte.net/series/alarms-over-time", request);
    fetch(url)
      .then(res => res.json())
      .then(data => callbackFunc(data))
      .catch(err => HandleError(err)); 
  }
}

function createHubRequestUrl(baseUrl: string, request: HubRequest): string {
  const url = new URL(baseUrl);
  if (request.startDate) {
    url.searchParams.append("start_date", request.startDate.toISOString().split("T")[0]);
  }
  if (request.endDate) {
    url.searchParams.append("end_date", request.endDate.toISOString().split("T")[0]);
  }
  if (request.deviceIDs && request.deviceIDs.length > 0) {
    request.deviceIDs.forEach(id => {
      url.searchParams.append("device_id", id.toString());
    });
  }
  if (request.direction) {
    url.searchParams.append("direction", request.direction);
  }

  if (request.alarmName) {
    url.searchParams.append("alarm", request.alarmName);
  }

  return url.toString();
}

const hubStore = new HubStore();
export default hubStore;
